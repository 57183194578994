<template>
    <div class="zt-page-content">
        <div v-if="showImgs" style="overflow-y:auto;">
            <!-- 上传自有商品 -->
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm1" label-width="120px">
                <div class="zt-block">
                    <div class="zt-block-head">基本信息</div>
                    <div class="zt-block-content">
                        <el-form-item label="商品名称" prop="name">
                            <el-input v-model="ruleForm.name" maxlength="30" placeholder="填写商品名称"></el-input>
                            <span class="font14-grey1" style="padding-left:10px;line-height:20px;position:relative;top:10px;"
                                >{{(ruleForm.name || '').length}}/30</span>
                        </el-form-item>
                        <el-form-item label="商品型号" prop="module_no">
                            <el-input v-model="ruleForm.module_no" placeholder="填写商品型号"></el-input>
                        </el-form-item>
                        <el-form-item label="商品分类名称">
                            <div class="flex">
                                <div style="margin-right:32px;">
                                    <el-cascader :options="classify" :show-all-levels="false" :props="{value:'id',label:'name',emitPath:false}"
                                        v-model="ruleForm.custom_cat_no" placeholder="请选择商品分类">
                                    </el-cascader>
                                </div>
                                <div>
                                    <router-link :to="{ path:'/agent/txgl' }" target="_blank">
                                        <el-button type="primary" plain>新建商品分类</el-button>
                                    </router-link>
                                </div>
                            </div>
                        </el-form-item>
                        <el-form-item label="单品类目" prop="cat_id">
                            <el-cascader :options="cats" :disabled="fx_product_id&&catId?true:false" :show-all-levels="false" :props="{value:'id',label:'name',emitPath:false}"
                                v-model="ruleForm.cat_id" placeholder="请选择类目">
                            </el-cascader>
                        </el-form-item>
                        <el-form-item label="单品风格" prop="style_no">
                            <el-select v-model="ruleForm.style_no" placeholder="请选择风格">
                                <el-option v-for="item in styles" :key="item.id" :label="item.name" :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </div>
                </div>
                <div class="zt-block">
                    <div class="zt-block-head">图片与视频</div>
                    <div class="zt-block-content">
                        <el-form-item label="3D 单品渲染图" prop="photo_render">
                            <div>
                                <UploadImgs v-if="showImgs" v-model="ruleForm.photo_render" prefix='product/render'></UploadImgs>
                            </div>
                        </el-form-item>
                        <el-form-item label="单品视频" prop="video" style="margin-bottom:10px;">
                            <div style="position:relative;">
                                <UploadVideo v-if="showImgs" v-model="ruleForm.video" prefix='product/video'></UploadVideo>
                            </div>
                        </el-form-item>
                        <el-form-item v-if="this.ruleForm.video && this.ruleForm.video.length>0" label="视频封面图" prop="video_thumb" style="margin-bottom:10px;">
                            <div>
                                <UploadImg v-if="showImgs" v-model="ruleForm.video_thumb" prefix='product/video/cover'></UploadImg>
                            </div>
                        </el-form-item>
                        <el-form-item label="素材图" style="margin-bottom:0px;">
                            <div>
                                <UploadImgss v-if="showImgs" v-model="ruleForm.photo_story" :limit="10" prefix='product/story'></UploadImgss>
                            </div>
                        </el-form-item>
                    </div>
                </div>
                <div class="zt-block btn-block">
                    <div style="max-width:400px;margin:0 auto;">
                        <div v-if="fx_product_id" class="flex flex-justify-around">
                            <!-- <el-button @click="preview()" size="small" type="text">
                                <i style="font-size:14px;position:relative;top:1px;margin-right:5px;" class="iconfont icon-sousuo"></i>
                                <span>上架预览</span>
                            </el-button> -->
                            <el-button size="small" type="primary" @click="back()">取消</el-button>
                            <el-button size="small" type="primary" @click="editSubmit()">保存</el-button>
                        </div>
                        <div v-else class="flex flex-justify-around">
                            <!-- <el-button @click="preview()" size="small" type="text">
                                <i style="font-size:14px;position:relative;top:1px;margin-right:5px;" class="iconfont icon-sousuo"></i>
                                <span>上架预览</span>
                            </el-button> -->
                            <!-- <el-button size="small" @click="saveDraft()" type="primary" plain>存草稿</el-button> -->
                            <el-button size="small" type="primary" @click="back()">取消</el-button>
                            <el-button size="small" @click="formSubmit()" type="primary">下一步</el-button>
                        </div>
                    </div>
                </div>
            </el-form>

        </div>

        
    </div>
</template>

<script>
    import _ from 'lodash';
    import { IMG_URL_PRE } from '@/config';
    import store from '@/store';
    import { getCats, getStyles, classifyList, getAttrs } from '@/service/item';
    import { uploadFile } from '@/service/common';
    import { fxDetailProduct,addFxSelfProduct,updateFxSelfProduct } from '@/service/sharer';
    import { fetchTree } from '@/util';
    import UploadImgs from '@/components/upload/UploadImgs.vue';
    import UploadImgss from '@/components/upload/UploadImgss.vue';
    import UploadVideo from '@/components/upload/UploadVideo.vue';
    import UploadImg from '@/components/upload/UploadImg.vue';

    export default {
        components:{ 
            UploadImgs,
            UploadImgss, 
            UploadVideo, 
            UploadImg 
        },
        props: {},
        computed: {},
        data() {
            let checkVideo = (rule, value, callback) => {
                if (!value) {
                    return callback(new Error('请上传视频封面图'));
                } else {
                    callback();
                }
            };
            return {
                imgUrl: IMG_URL_PRE,
                popTitle:null,
                popVisible:false,
                ruleForm: {
                    name: null,
                    module_no: null,
                    style_no: null,
                    custom_cat_no: null,
                    cat_id: null,
                    photo_render:[],
                    video:[],
                    video_thumb:null,
                    photo_story:[],
                },
                rules: {
                    name: [{ required: true, message: '请填写商品名称', trigger: 'blur' }],
                    style_no: [{ required: true, message: '请选择风格', trigger: 'blur' }],
                    cat_id: [{ required: true, message: '请选择类目', trigger: 'blur' }],
                    photo_render: [{ required: true, message: '请上传3D 单品渲染图', trigger: 'blur' }],
                    video_thumb: [{ required: true, validator: checkVideo, trigger: 'blur' }],
                },
                catList:[],
                cats: [],
                styles: [],
                price_type: 5, // 商品分类 0:版权购买，1:分成合作，5:销售公司
                classify: [],
                fx_product_id:null,
                catId:null,
                showImgs:false,
            }
        },
        watch: {
            'ruleForm.video': {
                handler(newVal, oldVal) {
                    // 删除视频同时删除图片
                    if (newVal && newVal.length==0) {
                        this.ruleForm.video_thumb=null;
                    }
                },
                deep: true
            }
        },
        async created() {
            this.fx_product_id = this.$route.query.id;
            this.catList = await getCats();
            this.cats = fetchTree(this.catList, null, { name: { dest: 'name' }, id: { dest: 'id' }, parent: 'parentId' });
            this.styles = await getStyles();
            if (this.$route.query.set_no) {
                this.ruleForm.set_no=this.$route.query.set_no;
            }
            let classify = await classifyList(this.$store.state.loginUser.comp.id);
            if (classify && classify.length>0) {
                classify = fetchTree(classify, null, { name: { dest: 'name' }, id: { dest: 'id' }, parent: 'parentId' });
                classify.forEach(e => {
                    if (e.children && e.children.length>0) {
                        this.classify.push(e);
                    }
                });
            }

        },
        mounted() {
            if (this.fx_product_id) {
                let _this = this;
                fxDetailProduct(this.fx_product_id).then(rst => {
                    console.log(rst);
                    this.catId = rst.cat.id;
                    if (rst) {
                        this.ruleForm = {
                            name: rst.name,
                            module_no: rst.module_no,
                            style_no: rst.style.id,
                            custom_cat_no:rst.custom_cat_no,
                            cat_id: rst.cat.id,
                            photo_render:rst.photo_render,
                            video:rst.video,
                            video_thumb:rst.video_thumb,
                            photo_story:rst.photo_story,
                        };
                        this.showImgs = true;
                    }
                    console.log(this.ruleForm);
                }).catch(err => {
                    console.log(err);
                    this.$message.error(err.message);
                });
            } else {
                this.showImgs = true;  
            }
        },
        methods: {
            back() {
                this.$router.go(-1);
            },
            // 存草稿
            saveDraft() {
                const _loading = this.$loading({
                    lock: true,
                    text: '拼命加载中......',
                });
                let _item = _.clone(this.ruleForm);
                addFxSelfProduct(_item).then(rst => {
                    _loading.close();
                    this.$message.success('草稿保存成功');
                    this.back();
                }).catch(err => {
                    _loading.close();
                    this.$message.error(err.message);
                });
            },
            // 编辑保存
            editSubmit() {
                let _item = _.clone(this.ruleForm);
                this.$refs['ruleForm1'].validate((valid) => {
                    if (valid) {
                        if (this.fx_product_id) {
                            _item.fx_product_id = this.fx_product_id;
                            updateFxSelfProduct(_item).then(rst => {
                                this.$message.success('保存成功');
                                this.back();
                            }).catch(err => {
                                this.$message.error(err.message);
                            });
                        }
                    }
                });
            },
            submitShow() {
                this.$refs['ruleForm1'].validate((valid) => {
                    if (valid) {
                        // this.popTitle = "温馨提示";
                        // this.popVisible = true;
                        this.zyspSubmit();
                    }
                });
            },
            submitHide() {
                this.popVisible = false;
            },
            formSubmit() {
                this.$refs['ruleForm1'].validate((valid) => {
                    if (valid) {
                        this.submit();
                    }
                });
            },
            submit() {
                const _loading = this.$loading({
                    lock: true,
                    text: '拼命加载中......',
                });
                let _item = _.clone(this.ruleForm);
                console.log(_item);
                addFxSelfProduct(_item).then(rst => {
                    _loading.close();
                    this.$message.success('保存成功');
                    this.back();
                    // this.$router.push('/agent/agentGoods/ItemSku/'+item.id);
                }).catch(err => {
                    _loading.close();
                    this.$message.error(err.message);
                });
            },
        }
    }
</script>
<style>

</style>
<style scoped src="../style.css"></style>
<style scoped lang="less">
    .el-steps {
        width: 700px;
        padding: 10px 51px;
    }
    .list-item {
        position:relative;
        padding:30px 0;
        border-bottom:1px solid #E6E6E6;
    }
    .list-item:last-child {
        border-bottom:0;
    }
    .add-accessory {
        height: 70px;
        border-top: 1px solid #E6E6E6;
        >span {
            font-size: 16px;
        }
        .addSeries {
            text-align: center;
            // padding: 10px 90px 0;
            cursor: pointer;
            >span {
                display: inline-block;
                vertical-align: top;
                font-size: 14px;
                color: #016FFF;
                &:first-child {
                    width: 18px;
                    height: 18px;
                    line-height: 15px;
                    text-align: center;
                    font-size: 18px;
                    color: #016FFF;
                    border: 1px solid #016FFF;
                    border-radius: 50%;
                    position: relative;
                    top: 2px;
                    margin-right: 5px;
                }
            }
        }
    }
    // .selected {
    //     margin-left: 53px;
    //     color: #808080;
    //     >div {
    //         margin: 0 0 8px 72px ;
    //         &:first-child {
    //             margin-left: 0;
    //         }
    //     }
    // }
    .btn-block {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 24px;
        .el-button {
            margin-left: 48px;
        }
        a {
            margin-left: 48px;
            margin-right: 10px;
        }
    }
    .add-btn {
        height: 80px;
        color: #016FFF;
        font-size: 16px;
        display: flex;
        align-items: center;
        padding: 0 46px;
        cursor: pointer;
        i {
            font-size: 18px;
            margin-right: 16px;
        }
    }
    .el-cascader,.el-input, .el-select {
        width: 240px;
    }
    .el-dialog {
        .el-dialog__body {
            >div {
                color: #808080;
                label {
                    margin-right: 23px;
                }
                &:last-child {
                    padding-bottom: 32px;
                    border-bottom: 1px solid #E6E6E6;
                    label {
                        display: block;
                    }
                }
            }
        }
    }
</style>